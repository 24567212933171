<template>
  <div class="my-3">
    <SummaryElement
      v-model="name"
      :has-default-save-callback="false"
      @save="updateProject"
    />

    <SummaryElement
      v-model="timezone"
      :has-default-save-callback="false"
      @save="updateProject"
    />

    <SummaryElement
      v-model="tPulseLinkField"
      :has-default-save-callback="false"
      @save="projectStore.updateTpulseLink"
    />
  </div>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement.vue"
import timezones from "@evercam/shared/constants/timezones"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default {
  components: {
    SummaryElement,
  },
  computed: {
    ...mapStores(useProjectStore),
    name: {
      get() {
        return {
          key: "name",
          label: "Name",
          value: this.projectStore.editedProject?.name,
          type: "TEXT_FIELD",
        }
      },
      set(name) {
        this.projectStore.editedProject = {
          ...this.projectStore.editedProject,
          name: name?.value,
        }
      },
    },
    tPulseLinkField: {
      get() {
        return {
          key: "tPulseLink",
          label: "T-pulse link",
          value: this.projectStore.tPulseLink,
          type: "TEXT_FIELD",
        }
      },
      set(tPulseLink) {
        this.projectStore.tPulseLink = tPulseLink?.value
      },
    },
    timezone: {
      get() {
        return {
          key: "timezone",
          label: "Timezone",
          value: this.projectStore.editedProject?.timezone,
          type: "SINGLE_SELECT",
          dataset: timezones,
        }
      },
      set(timezone) {
        this.projectStore.editedProject = {
          ...this.projectStore.editedProject,
          timezone: timezone?.value,
        }
      },
    },
  },
  methods: {
    async updateProject() {
      await this.projectStore.editProject(this.projectStore.editedProject)
    },
  },
}
</script>
