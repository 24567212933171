export default [
  {
    value: "name",
    text: "Camera Name",
    visible: true,
    width: 250,
  },
  {
    value: "status",
    text: "Status",
    visible: true,
    width: 170,
  },
  {
    value: "exid",
    text: "Camera ID",
    visible: true,
    width: 170,
  },
  {
    value: "ownerFullname",
    text: "Owner",
    width: 250,
    visible: true,
  },
  {
    value: "ownerEmail",
    text: "Email",
    width: 250,
    visible: true,
  },
]
