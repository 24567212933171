<template>
  <ERow>
    <ECol cols="12" md="8" class="pl-4 py-1">
      <span class="subtitle-2 black--text text--lighten-4">
        {{ router.serialNumber }}
        <CopyToClipboardBtn
          :text="`${router.serialNumber}`"
          icon
          color="primary"
        />
      </span>
      <OpenInNewWindowButton
        :target-link="`${router.rmsUrl}`"
        hint="Open In RMS"
      />
      <span v-if="isActiveRouter" class="green--text"> Active </span>
      <span v-else class="red--text"> Inactive </span>
    </ECol>
    <div class="d-flex align-center ml-auto">
      <v-btn
        v-if="!inline"
        color="primary"
        icon
        dark
        class="large-icon mr-5"
        @click="$emit('close')"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
    </div>
  </ERow>
</template>

<script lang="ts">
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import Vue, { PropType } from "vue"
import { Router, RouterStatus } from "@evercam/shared/types/router"

export default Vue.extend({
  name: "RouterSummaryDialog",
  components: { CopyToClipboardBtn, OpenInNewWindowButton },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    router: {
      type: Object as PropType<Router>,
      required: true,
    },
  },
  computed: {
    isActiveRouter() {
      return this.router.status === RouterStatus.Active
    },
  },
})
</script>
