<template>
  <v-container fluid class="pa-0">
    <ERow class="ma-0">
      <ECol cols="12" sm="12" md="3" lg="3" class="pl-0">
        <v-select
          v-model="status"
          placeholder="Search"
          :items="statuses"
          item-value="value"
          dense
          outlined
          multiple
          chips
          small-chips
          deletable-chips
          label="status"
          hide-details
          item-text="name"
        />
      </ECol>
      <ECol cols="12" sm="12" md="2" lg="2" class="pl-0">
        <v-select
          v-model="historyDay"
          :items="historyDays"
          outlined
          dense
          label="History Days"
        ></v-select>
      </ECol>
      <ECol cols="12" sm="12" md="3" lg="3" class="pl-0">
        <v-btn color="primary" @click="generateStatusReport()"
          >Generate Status Report</v-btn
        >
      </ECol>
      <ECol v-if="loading" cols="12" class="d-flex align-center justify-center">
        <EvercamLoadingAnimation />
      </ECol>
      <ECol v-else cols="12" class="py-0 mt-6" justify="center" align="stretch">
        <ETimeline
          class="e-w-full"
          :dark="$vuetify.theme.dark"
          :events-groups="eventsGroups"
          :start-date="fromDatetime"
          :end-date="toDatetime"
          :bar-height="20"
          :bar-y-padding="18"
        >
          <template #eventTooltip="{ event, active }">
            <div
              v-if="event && active"
              class="e-rounded-md e-border e-p-3 e-m-2 e-text-black e-w-80 e-bg-slate-50"
              :style="{ border: '1px solid lightgrey', background: '#f2f2f2' }"
            >
              <div class="e-flex e-justify-start e-items-center e-gap-4">
                <div>
                  <span class="e-mr-2 e-font-medium">From: &ThinSpace;</span>
                  <span class="e-text-xs">
                    {{
                      $moment(event.startDate).format("YYYY-MM-DD HH:mm:ss")
                    }}</span
                  >
                </div>
              </div>

              <div class="e-flex e-justify-start e-items-center e-gap-4">
                <div>
                  <span class="e-mr-2 e-font-medium">To: &ThinSpace;</span>
                  <span class="e-text-xs">
                    {{
                      $moment(event.endDate).format("YYYY-MM-DD HH:mm:ss")
                    }}</span
                  >
                </div>
              </div>

              <div class="e-flex e-justify-start e-items-center e-gap-4">
                <div>
                  <span class="e-mr-2 e-font-medium">status: &ThinSpace;</span>
                  <span class="e-text-xs e-font-semibold">
                    {{ event.status.toUpperCase() }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </ETimeline>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import { CameraStatuses } from "@/components/constants.js"
import { CameraStatus } from "@evercam/shared/types/camera"
import { AdminApi } from "@evercam/shared/api/adminApi"
import Vue from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      historyDay: 7,
      loading: false,
      status: [CameraStatus.Online, CameraStatus.Offline],
      statuses: CameraStatuses,
      eventsGroups: {},
      historyDays: [
        { text: "Today", value: 1 },
        { text: "7 Days", value: 7 },
        { text: "15 Days", value: 15 },
        { text: "30 Days", value: 30 },
      ],
      toDatetime: this.$moment(new Date()),
      fromDatetime: this.$moment(new Date()).subtract({ days: 7 }),
    }
  },
  head() {
    return {
      title: "Admin - Status",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  watch: {
    projectId() {
      this.getDataSet()
    },
  },
  mounted() {
    this.getDataSet()
  },
  methods: {
    getColorPerStatus(status: string) {
      const colors = {
        [CameraStatus.Decommissioned]: "#115e59",
        [CameraStatus.Offline]: "#dc2626",
        [CameraStatus.Online]: "#15803d",
        [CameraStatus.OfflineScheduled]: "#facc15",
        [CameraStatus.Waiting]: "#f97316",
        [CameraStatus.WaitingForSiteVisit]: "#a3a3a3",
        [CameraStatus.OnHold]: "#f43f5e",
        [CameraStatus.UnderMaintenance]: "#f87171",
      }

      return colors[status]
    },
    generateStatusReport() {
      this.fromDatetime = this.$moment(new Date()).subtract({
        days: this.historyDay,
      })
      this.getDataSet()
    },
    async getDataSet() {
      this.loading = true
      await AdminApi.logs
        .getAllCamerasStatusLogs({
          params: {
            historyDays: `${this.historyDay}`,
            projectId: `${this.projectId}`,
            status: this.status,
            from: `${this.fromDatetime.format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
            to: `${this.toDatetime.format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
          },
        })
        .then((response) => {
          const { data } = response
          data.forEach((item) => {
            this.eventsGroups = {
              ...this.eventsGroups,
              [item.measure]: {
                label: item.measure,
                color: "#eee",
                events: item.data.map((el) => {
                  return {
                    startDate: new Date(el.start).getTime(),
                    endDate: new Date(el.end).getTime(),
                    color: this.getColorPerStatus(el.state),
                    status: el.state,
                  }
                }),
              },
            }
          })
        })
      this.loading = false
    },
  },
})
</script>
