export default (self) => [
  {
    value: "fullname",
    text: "Name",
    visible: true,
    width: 150,
  },
  {
    value: "email",
    text: "Email",
    width: 150,
    visible: true,
  },
  {
    value: "persona",
    text: "Persona",
    width: 75,
    visible: true,
  },
  {
    value: "eventsCount",
    text: "# Actions",
    width: 50,
    visible: true,
    align: "center",
  },
  {
    value: "lastSeenAt",
    text: "Last seen",
    width: 90,
    visible: true,
    align: "center",
    toStringFn: (item, key) => self.formatDate(item[key]),
  },
  {
    value: "active",
    text: "WAU",
    width: 60,
    align: "center",
    visible: true,
  },
  {
    value: "activeOneMonth",
    text: "MAU",
    width: 60,
    align: "center",
    visible: true,
  },
]
