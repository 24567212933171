<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="eventsStatsTable"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="admin-data-table elevation-1"
        dense
        must-sort
        :loading="loading"
        loading-text="Please wait..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        item-key="event"
        :items-per-page="20"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="text-h6">
                {{ title }}
              </ECol>
              <v-spacer />
              <ECol cols="1" class="text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item="{ item }">
          <tr>
            <td
              v-for="header in headers"
              :key="header.value"
              class="text-start"
            >
              <span v-if="header.value == 'name'">
                {{ snakeCaseToTitleCase(item[header.value]) }}</span
              >
              <span v-else>{{ item[header.value] || "-" }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {},
      sortBy: "total",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.eventsStatsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
}
</script>
