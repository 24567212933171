export const events = [
  { name: "pageview_live", color: "rgb(54, 162, 235)" },
  { name: "pageview_recordings", color: "rgb(255, 99, 132)" },
  { name: "pageview_cameras", color: "rgb(255, 205, 86)" },
  { name: "pageview_sharing", color: "rgb(115,199,61)" },
  { name: "click_edit_snapshot_live", color: "#F0FFFF" },
  { name: "click_edit_snapshot_recordings", color: "#98FB98" },
  { name: "click_weather_current", color: "#BA55D3" },
  { name: "click_weather_history", color: "#6B8E23" },
  { name: "copy_compare_embedcode", color: "#FF69B4" },
  { name: "copy_compare_gifurl", color: "#800080" },
  { name: "copy_compare_mp4url", color: "#696969" },
  { name: "create_archive", color: "#D2B48C" },
  { name: "delete_archive", color: "#6495ED" },
  { name: "delete_share_request", color: "#40E0D0" },
  { name: "download_archive", color: "#A0522D" },
  { name: "download_compare", color: "#FFF5EE" },
  { name: "download_image", color: "#F0E68C" },
  { name: "download_edited_image", color: "#F0E68C" },
  { name: "pageview_account", color: "#848484" },
  { name: "pageview_activities", color: "#FFC0CB" },
  { name: "pageview_api", color: "#FFA07A" },
  { name: "pageview_archives", color: "#8880ff" },
  { name: "pageview_bim_compare", color: "#008080" },
  { name: "pageview_bim_info", color: "#D8BFD8" },
  { name: "pageview_compare", color: "#1E90FF" },
  { name: "pageview_embedded_live", color: "#00008B" },
  { name: "pageview_embedded_recordings", color: "#B22222" },
  { name: "pageview_gate_report", color: "#DB7093" },
  { name: "pageview_gate_report_info", color: "#4682B4" },
  { name: "pageview_integration", color: "#B0E0E6" },
  { name: "pageview_map", color: "#7B68EE" },
  { name: "pageview_recognition", color: "#9400D3" },
  { name: "pageview_snapmail_manage", color: "#FF1493" },
  { name: "pageview_snapmails", color: "#00FF7F" },
  { name: "pageview_timelapse_creator", color: "#DEB887" },
  { name: "pageview_timelapse", color: "#74d216" },
  { name: "click_timelapse_help", color: "#1e3900" },
  { name: "click_timelapse_export", color: "#bee8a6" },
  { name: "click_timelapse_save", color: "#00ff5d" },
  { name: "pageview_tutorials", color: "#FAFAD2" },
  { name: "share_archive_to_facebook", color: "#DAA520" },
  { name: "share_archive_to_linkedin", color: "#87CEEB" },
  { name: "share_archive_to_twitter", color: "#5F9EA0" },
  { name: "share_archive_to_whatsapp", color: "#FF8C00" },
  { name: "share_camera", color: "#4169E1" },
  { name: "show_xray", color: "#FFE4B5" },
  { name: "update_archive_title", color: "#800000" },
  { name: "update_share_camera_rights", color: "#778899" },
]
