<template>
  <div class="my-3">
    <div class="d-flex justify-between alig-center">
      <div class="subtitle-2 pl-4">Project features</div>
      <EditingButton
        :can-edit="canEdit"
        :is-saving="isUpdatingFeatureFlags"
        @start-editing="onStartEditing"
        @save="onSaveFeatureFlags"
        @cancel="onCancelEditing"
      />
    </div>
    <FeatureFlagsTree
      v-if="selectedFeatureFlags"
      :key="projectExid"
      v-model="selectedFeatureFlags"
      :items="featureFlags"
      :readonly="isUpdatingFeatureFlags || !canEdit"
    />
  </div>
</template>

<script>
import FeatureFlagsTree from "@/components/FeatureFlagsTree"
import EditingButton from "@/components/EditingButton"
import { ProjectFeatureFlags } from "@/components/constants"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default {
  components: {
    FeatureFlagsTree,
    EditingButton,
  },
  data() {
    return {
      isUpdatingFeatureFlags: false,
      canEdit: false,
      oldSelectedFeatureFlags: [],
      featureFlags: ProjectFeatureFlags,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    projectExid() {
      return this.projectStore.editedProject?.exid
    },
    selectedFeatureFlags: {
      get() {
        return this.projectStore.editedProject?.featureFlags
      },
      set(featureFlags) {
        this.projectStore.editedProject = {
          ...this.projectStore.editedProject,
          featureFlags,
        }
      },
    },
  },
  methods: {
    async updateProject() {
      await this.projectStore.editProject(this.projectStore.editedProject)
    },
    async onSaveFeatureFlags() {
      this.isUpdatingFeatureFlags = true
      await this.updateProject()
      this.canEdit = false
      this.isUpdatingFeatureFlags = false
      this.oldSelectedFeatureFlags = []
    },
    onStartEditing() {
      this.oldSelectedFeatureFlags = [...this.selectedFeatureFlags]
      this.canEdit = true
    },
    onCancelEditing() {
      this.selectedFeatureFlags = [...this.oldSelectedFeatureFlags]
      this.canEdit = false
    },
  },
}
</script>
